body {
  margin: 10;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body,
#root {
    padding: 0;
    margin: 0;
    height: 100%;
    background-image: url("/go.jpg");
    background-repeat: no-repeat;
    /* background-image: url("/bg.png"); */
    /* background-image: url("https://source.unsplash.com/random/1280x1024"); */
    font-family: sans-serif;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.8);
    background-blend-mode: darken;
    background-repeat: none;
}


.Particles {
    position: absolute;
    height: 100%;
    width: 100%;
}

canvas{
    position: absolute;
    top: 50%;
}


.LandingPage {
    display: flex;
    align-items: center;
    justify-content: center;

}

#JoinLobby {
    position: absolute;
    top: 70%;
    margin: 0 auto;
    width: 25%;
}

.ContainerViewBoard {
    float: left;
}

.ContainerViewSidebar {
    float: left;
    padding-left: 20px;
}

#alerts {
    height: 30px;
    line-height: 30px;
    padding: 5px;
}

#board {
    position: relative;
}

.intersection {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    position: absolute;
    box-sizing: border-box;
}

.intersection:before {
    border-top: 2px #000 solid;
    width: 40px;
    display: block;
    content: "";
    top: 18px;
    position: absolute;
    z-index: -1;
}

.intersection:after {
    border-left: 2px #000 solid;
    height: 40px;
    display: block;
    content: "";
    left: 20px;
    top: 0px;
    position: absolute;
    z-index: -1;
}

.intersection.black,
.intersection.white {
    border: 1px solid #242424;
}

:root {
    --bgcolor1: #5632a8;
    --bgcolor2: #fbfbca;
    --bgimage1: "";
    --bgimage2: "";
}

.intersection.black {
    background-color: #5632a8;
    background-color: var(--bgcolor1);
    background-image: "";
    background-image: var(--bgimage1);
}

.intersection.white {
    background-color: #fbfbca;
    background-color: var(--bgcolor2);
    background-image: "";
    background-image: var(--bgimage2);
}



